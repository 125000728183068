<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Administrator Log
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <div class="material-card-content">
      <adminLog
        v-if="validateIfHasPermission([
          permissions.INVITE_USERS.value.dbValue
        ])"
      />
    </div>
  </div>
</template>

<script>
import adminLog from '@/components/executions/AdminLog.vue';
import UserMixin from '@/mixins/users';
import RoleMixin from '@/mixins/roles';
import vtulEnums from '../../../cross/index';

const { permissions } = vtulEnums.enum.roles;

export default {
  name: 'CompanyUsers',
  metaInfo: {
    title: 'Team',
  },
  components: {
    adminLog,
  },
  mixins: [UserMixin, RoleMixin],
  data() {
    return {
      permissions,
    };
  },
};
</script>

<style scope>
  .invite-email {
    display: flex;
    flex-flow: row;
  }
  .invite-email-text {
    margin-left: 1rem;
    line-height: 34px;
  }
  .has-margin-top{
    margin-top:0.7rem;
  }
</style>
