<template>
  <card
    external-card="card material-card-overflow "
    internal-card="material-card-content"
  >
    <div
      slot="body"
    >
      <div>
        <div class="has-margin-top">
          <b-field
            style="flex: 1"
          />
          <b-table
            :data="filteredData"
            :paginated="filteredData.length > maxQuantity"
            :per-page="maxQuantity"
            :mobile-cards="false"
            default-sort="name"
            class="table-members"
          >
            <b-table-column
              v-for="(column, index) in columns"
              :key="index"
              :label="column.title"
              :visible="column.visible"
              :field="column.field"
              style="white-space: nowrap"
            >
              <template
                v-slot="props"
              >
                <span
                  v-if="column.field === 'email'"
                  class="invite-email"
                >
                  <UserImage
                    :key="props.row.email ? props.row.email : ''"
                    :email="props.row.user ? props.row.user : ''"
                    :size="25"
                  />
                  <span class="invite-email-text">
                    {{ props.row.user ? props.row.user : '' }}
                  </span>
                </span>
                <span v-if="column.field === 'description'">
                  {{ props.row.action ? props.row.action : 'Name not registered' }}
                </span>
                <span v-if="column.field === 'date'">
                  <timeago
                    v-if="props.row.createdAt"
                    :since="props.row.createdAt"
                    no-future
                  />
                </span>
              </template>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                      icon="file"
                      size="is-large"
                    />
                  </p>
                  <p>No logs made yet</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import Fuse from 'fuse.js';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import card from '@/components/cross/Card.vue';
import userImage from '@/components/cross/UserImage.vue';
import crossHelper from '@/helpers/cross';
import LodingMixin from '@/mixins/loading';
import ErrorMixin from '@/mixins/error';
import UserMixin from '@/mixins/users';
import RoleMixin from '@/mixins/roles';
import vtulEnums from '../../../../cross';

const { permissions } = vtulEnums.enum.roles;
const fuseOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true,
  maxPatternLength: 0,
  minMatchCharLength: 1,
  keys: ['name', 'email'],
};
export default {
  name: 'AdminLog',
  components: {
    card,
    UserImage: userImage,
  },
  mixins: [LodingMixin, ErrorMixin, UserMixin, RoleMixin],
  data() {
    return {
      search: '',
      filteredData: [],
      maxQuantity: 20,
      deviceName: '',
      usersRoles: [],
      fuse: null,
      columns: [
        {
          id: 'email', title: 'User email', field: 'email',
        },
        {
          id: 'description', title: 'Description', field: 'description',
        },
        {
          id: 'date', title: 'Date', field: 'date',
        },
      ],
      crossHelper,
      permissions,
    };
  },
  computed: {
    ...mapGetters('user', { findUsersInStore: 'find' }),
    ...mapGetters('admin-log', { findAdminLogInStore: 'find' }),
    ...mapGetters('roles', { findRolesInStore: 'find' }),
    ...mapGetters('memberships', { findMembershipsInStore: 'find' }),
    adminLog() {
      const log = this.findAdminLogInStore({
        query: {
          $sort: { createdAt: -1 },
        },
      });

      this.updateFuseData(log.data);
      return log.data;
    },
  },
  watch: {
    adminLog() {
      this.searching();
    },
    memberships() {
      this.searching();
    },
  },
  async created() {
    this.searching(); // Update from local storage
    this.fetchMembershipsFromApi();
    this.fetchAdminLog(null, 'findAdminLog');
  },
  methods: {
    ...mapActions('user', { findUsers: 'find' }),
    ...mapActions('admin-log', { findAdminLog: 'find' }),
    ...mapActions('roles', { findRoles: 'find' }),
    ...mapActions('devices', { findDeviceName: 'find' }),
    formatDate(date) {
      return moment(date).format('LLL');
    },
    searching() {
      const input = this.search;
      if (input && this.fuse) {
        this.filteredData = this.fuse.search(input);
      } else {
        this.filteredData = this.adminLog;
      }
      this.filteredData = this.filteredData.filter((user) => user.user !== 'support@boardgent.com');
    },
    updateFuseData(data) {
      this.fuse = new Fuse(data, fuseOptions);
    },

    async fetchAdminLog(skip, action) {
      let skipLog = skip;
      const sortLog = { createdAt: 1 };
      if (!skipLog) skipLog = 0;
      const query = {
        $sort: sortLog,
        $skip: skipLog,
        $limit: 50,
        companyId: this.currentCompany.id,
      };
      const { total, data } = await this[action]({ query });
      // data.forEach(async (log) => {
      //   // eslint-disable-next-line no-param-reassign
      //   if (log.deviceId) {
      //     const deviceName = await this.getName(log.deviceId);
      //     // eslint-disable-next-line no-param-reassign
      //     log.deviceId = deviceName;
      //   }
      // });
      this.total = total;
      skipLog += data.length;
      if (skipLog < total) {
        await this.fetchAdminLog(skipLog, action);
      }
    },
    // async getName(device) {
    //   const { data } = await this.findDeviceName({
    //     query: {
    //       id: device,
    //       companyId: this.currentCompany.id,
    //     },
    //   });
    //   return data[0].name;
    // },
  },
};
</script>
<style>
  .table-members > .table-wrapper {
    overflow-x: auto;
  }
 .red-vtul-icon{
   color:#632d8e;
 }
</style>
